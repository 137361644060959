import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import QuestionSection from '../components/QuestionSection';
import PreQuestions from '../components/PreQuestion';
import EmailSection from '../components/EmailSection';
import { fetchAllData  } from '../data';
import { submitFormData } from '../remote-data';
import { Question, Section, Answer, PreQuestionAnswer, PreQuestionData, Result, SubmissionData } from '../types';
import { calculateResult } from '../utils/caluculateResult';
import Banner from '../components/Banner';
import { Badge, CircularProgress, styled } from '@mui/material';
import { setUserDemographics, trackUserProgress, setUserResult } from '../firebase';
import { v4 as uuidv4 } from 'uuid';

const imageUrls = [
  '/img/header-2.png',
  '/img/futoko-shindan.png',
  '/img/btn_cv.png',
  '/img/worries.png'
];

const ProgressWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: 20,
  width: '100%',
  marginBottom: theme.spacing(2),
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '100%',
  borderRadius: 10,
  '& .MuiLinearProgress-bar': {
    borderRadius: 10,
  },
}));

const ProgressText = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  color: theme.palette.common.white,
  fontWeight: 'bold',
  textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
}));


const FormPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState('');
  const [questions, setQuestions] = useState<Question[]>([]);
  const [sections, setSections] = useState<Section[]>([]);
  const [preQuestions, setPreQuestions] = useState<PreQuestionData[]>([]);
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [preQuestionAnswers, setPreQuestionAnswers] = useState<PreQuestionAnswer>({
    grade: '',
    relation: '',
    prefecture: '',
    situation: '',
  });
  const [currentSectionIndex, setCurrentSectionIndex] = useState(-1);
  const [unansweredCount, setUnansweredCount] = useState(0);
  
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [loadedImagesCount, setLoadedImagesCount] = useState(0);

  const topRef = useRef<HTMLDivElement>(null);
  const preQuestionsRef = useRef<HTMLDivElement>(null);

  const currentSection = sections[currentSectionIndex];
  const currentQuestions = questions.filter(q => q.sectionId === currentSection?.id);
  const progress = (answers.length / questions.length) * 100;

  const saveStateToLocalStorage = (
    answers: Answer[], 
    preQuestionAnswers: PreQuestionAnswer, 
    currentSectionIndex: number,
    email: string
  ) => {
    localStorage.setItem('formState', JSON.stringify({ answers, preQuestionAnswers, currentSectionIndex, email }));
  };

  const clearLocalStorage = () => {
    localStorage.removeItem('formState');
  };

  const handleStartDiagnosis = () => {
    if (preQuestionsRef.current) {
      preQuestionsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const emailParam = searchParams.get('email');
    if (emailParam && currentSectionIndex === -1) {
      setEmail(emailParam);
      saveStateToLocalStorage(answers, preQuestionAnswers, currentSectionIndex, emailParam);
      
      // 現在のクエリパラメータをコピー
      const newSearchParams = new URLSearchParams(searchParams);
      // emailパラメータのみ削除
      newSearchParams.delete('email');
      
      // 他のパラメータがある場合は"?"を付けて、ない場合は空文字列に
      const queryString = newSearchParams.toString();
      const newUrl = queryString 
        ? `${location.pathname}?${queryString}`
        : location.pathname;
        
      navigate(newUrl, { replace: true });
    }
  }, [location, navigate, searchParams]);

  // メールアドレスのバリデーション
  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!email) {
      setEmailError('メールアドレスを入力してください');
      return false;
    }
    if (!re.test(email)) {
      setEmailError('有効なメールアドレスを入力してください');
      return false;
    }
    setEmailError('');
    return true;
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    if (newEmail) {
      validateEmail(newEmail);
    } else {
      setEmailError('');
    }
  };

  useEffect(() => {
    if (currentQuestions && answers) {
      const count = currentQuestions.filter(q => !answers.find(a => a.questionId === q.id)).length;
      setUnansweredCount(count);
    }
  }, [currentQuestions, answers]);

  useEffect(() => {
    const loadImage = (url: string) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => {
          setLoadedImagesCount(prevCount => prevCount + 1);
          resolve(url);
        };
        img.onerror = reject;
      });
    };

    Promise.all(imageUrls.map(loadImage))
      .then(() => setImagesLoaded(true))
      .catch(error => console.error('Error loading images:', error));
  }, []);

  useEffect(() => {
    const loadData = async () => {
      const { questions, sections, preQuestions } = await fetchAllData();
      setQuestions(questions);
      setSections(sections);
      setPreQuestions(preQuestions);

      const savedState = loadStateFromLocalStorage();
      if (savedState) {
        setAnswers(savedState.answers);
        setPreQuestionAnswers(savedState.preQuestionAnswers);
        setCurrentSectionIndex(savedState.currentSectionIndex);
        setEmail(savedState.email);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (currentSectionIndex >= 0) {
      trackUserProgress(currentSectionIndex);
    }
  }, [currentSectionIndex]);

  const handlePreQuestionAnswer = (name: string, value: string) => {
    setPreQuestionAnswers(prev => {
        const updatedPreQuestionAnswers = { ...prev, [name]: value };
        saveStateToLocalStorage(answers, updatedPreQuestionAnswers, currentSectionIndex, email);
        return updatedPreQuestionAnswers;
    });
  };

  const handlePreQuestionSubmit = () => {
    if (!validateEmail(email)) {
      alert('メールアドレスが正しくありません。');
      return;
    }
    if (Object.values(preQuestionAnswers).every(value => value !== '')) {
      trackUserProgress(-1);
      setUserDemographics(preQuestionAnswers.grade, preQuestionAnswers.relation, preQuestionAnswers.prefecture, preQuestionAnswers.situation);
      setCurrentSectionIndex(0);
    } else {
      alert('すべての事前質問に回答してください。');
    }
  };

  const handleAnswer = (questionId: number, selectionNumber: number, selectionText: string) => {
    setAnswers(prevAnswers => {
        const newAnswers = prevAnswers.filter(a => a.questionId !== questionId);
        const updatedAnswers = [...newAnswers, { questionId, selectionNumber, selectionText }];
        saveStateToLocalStorage(updatedAnswers, preQuestionAnswers, currentSectionIndex, email);
        return updatedAnswers;
    });
  };

  const handleSubmit = async () => {
    if (answers.length === questions.length) {
      trackUserProgress(sections.length);
      const result = calculateResult(questions, sections, answers, preQuestionAnswers);
      setUserResult(result.overallGrade);

      const coupon = searchParams.get('coupon') || "";
      const requestId = uuidv4(); // リクエストIDを生成

      const submissionData: SubmissionData = { preQuestionAnswers, answers, result, email, coupon, requestId };

      submitFormData(submissionData);
      saveResultToLocalStorage(submissionData);
      clearLocalStorage();
      navigate({
        pathname: '/result',
        search: searchParams.toString()
      });
    } else {
      alert('すべての質問に回答してください。');
    }
  };
  
  const loadStateFromLocalStorage = () => {
    const savedState = localStorage.getItem('formState');
    return savedState ? JSON.parse(savedState) : null;
  };
  
  const saveResultToLocalStorage = (submissionData: SubmissionData) => {
    localStorage.setItem('submissionData', JSON.stringify(submissionData));
  };

  const handleNextSection = () => {
    const unansweredQuestions = currentQuestions.filter(q => !answers.find(a => a.questionId === q.id));
    if (unansweredQuestions.length > 0) {
      // 未回答の質問がある場合、最初の未回答の質問にスクロール
      const firstUnansweredIndex = currentQuestions.findIndex(q => !answers.find(a => a.questionId === q.id));
      const questionElement = document.getElementById(`question-${unansweredQuestions[0].id}`);
      questionElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else if (currentSectionIndex < sections.length - 1) {
      // 全ての質問に回答済みの場合、次のセクションへ
      const newIndex = currentSectionIndex + 1;
      setCurrentSectionIndex(newIndex);
      saveStateToLocalStorage(answers, preQuestionAnswers, newIndex, email);
    } else {
      // 最後のセクションの場合、診断を完了
      handleSubmit();
    }
  };

  const handlePreviousSection = () => {
    if (currentSectionIndex > 0) {
        const newIndex = currentSectionIndex - 1;
        setCurrentSectionIndex(newIndex);
        saveStateToLocalStorage(answers, preQuestionAnswers, newIndex, emailError);
    } else if (currentSectionIndex === 0) {
        setCurrentSectionIndex(-1);  // Go back to pre-questions
        saveStateToLocalStorage(answers, preQuestionAnswers, -1, email);
    }
  };

  if (!imagesLoaded) {
    return (
      <Container maxWidth="md">
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
          <CircularProgress />
          {/* <Typography variant="h6" sx={{ mt: 2 }}>
            読み込んでいます... ({loadedImagesCount}/{imageUrls.length})
          </Typography> */}
        </Box>
      </Container>
    );
  }

  return (
    <>
      <Box sx={{ 
        mt: 2,        // 上の余白を大きく
        ml: 2,        // 左の余白を大きく
        mb: 1,        // 下の余白を追加
        display: 'block',  // flexではなくblockに変更
        width: '150px'    // 固定幅を設定
      }} ref={topRef}>
        <Banner 
          imageUrl="/img/header-2.png" 
          altText="ヘッダー"
          maxWidth="100%"
          maxHeight="auto"
          animate={false}
        />
      </Box>
      {currentSectionIndex === -1 && (
        <>
        <Banner 
          imageUrl="/img/futoko-shindan.png" 
          altText="不登校診断とは"
          maxWidth="100%"
          maxHeight="100%"
          animate={false}  // アニメーションを有効化
        />
        {/* <Box 
          onClick={handleStartDiagnosis}
          sx={{ cursor: 'pointer' }}
        >
          <Banner 
            imageUrl="/img/btn_cv.png" 
            altText="診断を受ける"
            maxWidth="100%"
            maxHeight="100%"
            animate={false}
          />
        </Box>
        <Banner 
          imageUrl="/img/worries.png" 
          altText="悩み"
          maxWidth="100%"
          maxHeight="100%"
          animate={false}  // アニメーションを有効化
        /> */}
        <Box maxWidth="md">
          <EmailSection
            email={email}
            emailError={emailError}
            onChange={handleEmailChange}
          />
        </Box>
        <Box ref={preQuestionsRef}>
          <PreQuestions 
            preQuestions={preQuestions} 
            answers={preQuestionAnswers}
            onAnswer={handlePreQuestionAnswer}
            onSubmit={handlePreQuestionSubmit}
          />
        </Box>
        </>
      )}
    <Container maxWidth="md">
      <Box sx={{ mt: 2 }}>
        {currentSectionIndex >= 0 && (
          <>
            <ProgressWrapper>
              <StyledLinearProgress variant="determinate" value={progress} />
              <ProgressText variant="body2">{`${Math.round(progress)}%回答済み`}</ProgressText>
            </ProgressWrapper>
            {currentSection && (
              <div id={`section-${currentSection.id}`}>
                <QuestionSection
                  key={currentSection.id}
                  section={currentSection}
                  questions={currentQuestions}
                  answers={answers}
                  onAnswer={handleAnswer}
                  isFirstQuestion={currentSectionIndex === 0}
                />
              </div>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Button
                variant="outlined"
                onClick={handlePreviousSection}
              >
                {currentSectionIndex === 0 ? '事前質問に戻る' : '前のセクション'}
              </Button>
              <Badge badgeContent={unansweredCount} color="error" invisible={unansweredCount === 0}>
                <Button
                  variant="contained"
                  onClick={handleNextSection}
                >
                  {unansweredCount > 0 ? '未回答の項目へ飛ぶ' : currentSectionIndex < sections.length - 1 ? '次のセクション' : '診断を完了する'}
                </Button>
              </Badge>
            </Box>
          </>
        )}
      </Box>
    </Container>
    </>
  );
};

export default FormPage;