import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import ResultOverviewComponent from '../components/ResultOverviewComponent';
import SectionResult from '../components/SectionResult';
import { fetchResult, submitFormData } from '../remote-data';
import { PreQuestionAnswer, Result, SectionResultOverview, ResultOverview, SubmissionData } from '../types';
import Banner from '../components/Banner';
import { v4 as uuidv4 } from 'uuid';

const order = ['lifestyle', 'digitalAttitude', 'relationship', 'selfAffirmation', 'mindset'];

const ResultPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [submissionData, setSubmissionData] = useState<SubmissionData | null>(null);
  const [searchParams] = useSearchParams();
  const [result, setResult] = useState<Result | null>(null);
  const [demographics, setDemographics] = useState<PreQuestionAnswer | null>(null);
  const [loading, setLoading] = useState(true); // ローディング状態を管理
  const [userId, setUserId] = useState<string | null>(null); // `userId` を保存
  const [isSubmitting, setIsSubmitting] = useState(false); // 再送中の状態
  const [resendMessage, setResendMessage] = useState<string | null>(null); // メッセージ表示
  const [isModalOpen, setIsModalOpen] = useState(false); // モーダル表示管理
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    // `user-results` から来た場合、`state` に `userId` が存在する
    const state = location.state as { userId?: string };
    if (state?.userId) {
      setUserId(state.userId);
    }
  }, [location.state]);

  const coupon = searchParams.get('coupon'); // クーポンを取得

  const getFeedbackType = () => {
    return coupon === 'SUDACHI_SUPPORT' ? 'complete_feedback' : 'partial_feedback';
  };

  const resultToResultOverview = (r: Result) => {
    const sectionResultOverviews: SectionResultOverview[] = r.sectionResults.map((sectionResult) => {
      return {
        section: sectionResult.section,
        totalPoints: sectionResult.totalPoints,
        grade: sectionResult.grade
      };
    });
    const resultOverview: ResultOverview = {
      totalPoints: r.totalPoints,
      overallGrade: r.overallGrade,
      overallComment: r.overallComment,
      sectionResultOverviews: sectionResultOverviews
    };
    return resultOverview;
  };

  useEffect(() => {
    if (id) {
      setLoading(true); // ローディング開始
      fetchResult(id)
        .then(({ result: fetchedResult, demographics: fetchedDemographics }) => {
          if (fetchedResult) {
            // ソート順を配列で定義
            fetchedResult.sectionResults.sort((a, b) => order.indexOf(a.section.id) - order.indexOf(b.section.id));
            fetchedResult.sectionResults.forEach(sectionResult => {
              sectionResult.questions.sort((a, b) => a.question.questionNumber - b.question.questionNumber);
            });
          }
          setResult(fetchedResult);
          setDemographics(fetchedDemographics);
          console.log(fetchedResult);
        })
        .catch((error) => {
          console.error('Error fetching result:', error);
          navigate('/');
        })
        .finally(() => setLoading(false)); // ローディング終了
    } else {
      const submissionDataJson = localStorage.getItem('submissionData');
      if (submissionDataJson) {
        const submissionData: SubmissionData = JSON.parse(submissionDataJson);
        const savedResult = submissionData.result;
        const savedDemographics = submissionData.preQuestionAnswers;
        setSubmissionData(submissionData);

        savedResult.sectionResults.sort((a, b) => order.indexOf(a.section.id) - order.indexOf(b.section.id));
        savedResult.sectionResults.forEach(sectionResult => {
          sectionResult.questions.sort((a, b) => a.question.questionNumber - b.question.questionNumber);
        });
        setResult(savedResult);
        setDemographics(savedDemographics);
        console.log(savedResult);
      } else {
        console.log("データが見つかりませんでした");
        navigate('/');
      }
      setLoading(false); // ローディング終了
    }
  }, [id, navigate]);

  if (loading) {
    // ローディング中のUI
    return (
      <Container maxWidth="md">
        <Box sx={{ my: 4, textAlign: 'center' }}>
          <CircularProgress />
          <Typography variant="h6" sx={{ mt: 2 }}>
            結果を読み込んでいます...
          </Typography>
        </Box>
      </Container>
    );
  }

  if (!result) {
    // 結果が見つからない場合
    return (
      <Container maxWidth="md">
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom align="center" color="primary">
            診断結果が見つかりません
          </Typography>
        </Box>
      </Container>
    );
  }

  const handleViewPastResults = () => {
    if (userId) {
      // `userId` が存在する場合は `user-results` に戻る
      navigate(`/user-results/${userId}`);
    } else {
      // `userId` がない場合はモーダルを開く
      setIsModalOpen(true);
    }
  };

  const handleRequestResend = async () => {
    if (!submissionData) return;
    setIsSubmitting(true); // ローディング状態を開始
    setResendMessage(null); // メッセージをリセット

    try {
      await submitFormData(submissionData);
      setResendMessage('メールを再送しました。'); // 成功メッセージ
    } catch (error) {
      console.error('メール再送に失敗しました:', error);
      setResendMessage('メールの再送に失敗しました。再度お試しください。'); // 失敗メッセージ
    } finally {
      setIsSubmitting(false); // ローディング状態を終了
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setResendMessage(null); // メッセージをリセット
  };
  return (
    <>

      <Container maxWidth="md">
        <Box sx={{ mt: 2 }}>
          <Box sx={{ 
            mt: 2,        // 上の余白を大きく
            ml: 2,        // 左の余白を大きく
            mb: 1,        // 下の余白を追加
            display: 'block',  // flexではなくblockに変更
            width: '150px'    // 固定幅を設定
          }}>
            <Banner 
              imageUrl="/img/header-2.png" 
              altText="ヘッダー"
              maxWidth="100%"
              maxHeight="auto"
              animate={false}
            />
          </Box>
          <Box>
            <ResultOverviewComponent resultOverview={resultToResultOverview(result)} />
          </Box>
        </Box>
        <Box sx={{ textAlign: 'center', mt: 3 }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleViewPastResults}
          >
            過去の診断結果を見る
          </Button>
        </Box>
      </Container>

      <Box>
        {result.sectionResults.map(sectionResult => (
          <SectionResult key={sectionResult.section.id} sectionResult={sectionResult} feedbackType={getFeedbackType()} />
        ))}
      </Box>

      <Modal
      open={isModalOpen}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isModalOpen}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 6,
            pt: 8,
            pb: 8,
            borderRadius: 2,
            textAlign: 'center',
            width: '90%',
            maxWidth: '600px'
          }}
        >
          {/* 閉じるボタン */}
          {/* <IconButton
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <IconX size={24} />
          </IconButton> */}

          <Typography variant="h5" sx={{ mb: 4, fontWeight: 'bold' }}>
            診断受診時に送信されたメールをご確認ください
          </Typography>

          <Typography variant="body1" sx={{ mb: 4, fontSize: '1.1rem', lineHeight: 1.6 }}>
            メールが届いていない場合、
            <Box component="span" sx={{ display: 'block', fontWeight: 'bold', my: 2 }}>
              futoukou-shindan@sudachi.support
            </Box>
            からのメールが届くように設定を確認してください。
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={handleRequestResend}
            disabled={isSubmitting}
            sx={{
              mt: 2,
              py: 1.5,
              px: 4,
              fontSize: '1.1rem'
            }}
          >
            {isSubmitting ? <CircularProgress size={24} /> : 'メールを再送する'}
          </Button>

          {resendMessage && (
            <Typography 
              variant="body1" 
              color={resendMessage.includes('失敗') ? 'error' : 'primary'} 
              sx={{ 
                mt: 3,
                fontWeight: 'medium'
              }}
            >
              {resendMessage}
            </Typography>
          )}
        </Box>
      </Fade>
    </Modal>
    </>
  );
};

export default ResultPage;
